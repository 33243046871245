import { when } from 'mobx';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useNavigation } from '../../lib/hooks/useNavigation';
import { PersonalizationProvider } from '../../lib/hooks/usePersonalizations';
import { useSession } from '../../lib/store/useStores';
import type { PageWithContext } from '../../lib/utils/context';
import { PAGE_OPTIONS } from '../../lib/utils/page';
import { getAuthLayout } from '../../page-templates/auth-page';
import { FormPageTemplate } from '../../page-templates/form-page';
import { LocalLink } from '../../shared/util/link';
const Login: PageWithContext = observer(() => {
  const router = useRouter();
  const {
    next
  } = router.query as {
    next?: string;
  };
  const sessionStore = useSession();
  const {
    phoneVerificationStore
  } = sessionStore;
  useNavigation({
    action: 'HELP_CENTER',
    showLinks: false
  });
  useEffect(() => when(() => sessionStore.loggedIn, () => router.replace(next ? new LocalLink({
    base: next
  }).serialize(router) : '/')), []);
  return <PersonalizationProvider value={phoneVerificationStore.personalizations}>
            <FormPageTemplate {...PAGE_OPTIONS.LOGIN} />
        </PersonalizationProvider>;
});
Login.getLayout = getAuthLayout();
export default Login;