import { useEffect } from 'react'

import type { HeaderActionType } from '../../components/layout/header/actions/header-action-button'
import { navigationStore } from '../store/ui/navigation'

export const useNavigation = ({
    showLinks = true,
    action = 'DOWNLOAD_STEP',
}: {
    showLinks?: boolean
    action?: HeaderActionType
}) => {
    // optionally update navigation state if each prop is defined
    useEffect(() => {
        navigationStore.set({ showLinks, action })

        return () => {
            navigationStore.reset()
        }
    }, [showLinks, action])
}
