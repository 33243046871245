import type { DependencyList } from 'react'
import { useEffect } from 'react'

import type { DevAction, DevLink } from '../store/ui/dev-menu'
import { devMenuStore } from '../store/ui/dev-menu'

export const useDevMenuItem = (
    item: DevLink | DevAction,
    deps: DependencyList = []
) => {
    useEffect(() => {
        devMenuStore.add(item)
        return () => devMenuStore.remove(item)
    }, deps)
}
