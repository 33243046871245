import { when } from 'mobx';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import type { FunctionComponent, ReactNode } from 'react';
import { useContext, useEffect, useState } from 'react';
import { Spinner } from '../components/spinner';
import { useDevMenuItem } from '../lib/hooks/useDevMenuItem';
import { useDidMount } from '../lib/hooks/useDidMount';
import { SessionStore } from '../lib/store/session';
import { SessionContext } from '../lib/store/session/context';
import { DevActionGroup, DevActionName } from '../lib/store/ui/dev-menu';
import { useStores } from '../lib/store/useStores';
import { LINK } from '../shared/util/link';

/**
 * Persistent AuthLayout wrapper for login flow.
 */
export const getAuthLayout = (requireAuthorization = false, fallback: ReactNode = null) => (page: ReactNode) => {
  return <SessionWrapper requireAuthorization={requireAuthorization} fallback={fallback}>
                {page}
            </SessionWrapper>;
};

/**
 * This wrapper will display a fallback if the session is not loaded,
 * so it's safe to use useSession() in the component.
 */
export const SessionWrapper: FunctionComponent<Props> = observer(({
  children,
  requireAuthorization,
  fallback = null
}) => {
  const mounted = useDidMount();
  const {
    sdk,
    linkingStore,
    featureOverrideStore
  } = useStores();
  const outerValue = useContext(SessionContext);
  const [session] = useState(outerValue ?? new SessionStore(sdk, linkingStore, featureOverrideStore));
  const router = useRouter();
  const {
    logout,
    authorizedStore,
    loggedIn
  } = session;
  const userId = authorizedStore?.userStore.user.id;
  useEffect(() => {
    if (outerValue) return;
    session.restore();
  }, [outerValue, session]);
  useEffect(when(() => session.loaded && !session.loggedIn && !!requireAuthorization, () => router.push(LINK.login(router.pathname).serialize())), [requireAuthorization]);
  if (!mounted) return <Spinner size='large' />;
  return <SessionContext.Provider value={session}>
                {requireAuthorization && !loggedIn ? fallback : children}
                {userId && <DevMenuItems userId={userId} logout={logout} />}
            </SessionContext.Provider>;
});
const DevMenuItems: FunctionComponent<{
  userId: string;
  logout: () => void;
}> = ({
  userId,
  logout
}) => {
  useDevMenuItem({
    group: DevActionGroup.USER,
    name: DevActionName.OPEN_IN_ADMIN,
    href: LINK.admin.user(userId ?? '')
  }, [userId]);
  useDevMenuItem({
    group: DevActionGroup.USER,
    name: DevActionName.LOGOUT,
    async onActivate() {
      logout();
      window.location.reload();
    }
  }, [userId]);
  return null;
};
type Props = {
  children: ReactNode;
  fallback?: ReactNode;
  requireAuthorization?: boolean;
};