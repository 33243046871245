import { useRouter } from 'next/router';
import type { FunctionComponent } from 'react';
import type { Control } from 'react-hook-form';
import { Spinner } from '../components/spinner';
import { Fieldset } from '../contentful-components/fieldset';
import { Submit } from '../contentful-components/forms/submit';
import { PageLoadingState } from '../contentful-components/loading-state';
import { PageMeta } from '../contentful-components/page-meta';
import { renderSection } from '../contentful-components/section-resolver';
import type { FieldsetFragment } from '../graphql/fragments/FieldsetFragment.graphql';
import type { SubmitFragment } from '../graphql/fragments/SubmitFragment.graphql';
import type { CustomFieldsetId } from '../graphql/hooks/form-page';
import { useFormPage } from '../graphql/hooks/form-page';
import { EMPTY_COLLECTION } from '../graphql/placeholders';
import { useMode } from '../lib/hooks/useMode';
import { suspense } from '../lib/utils/suspense';
import type { ThemeMode } from '../lib/utils/theme';
import type { PageTemplateProps } from './generic-page';
import styles from './page.module.scss';

/**
 * Allows you to create a form page to be used under a specific path.
 * Automatically loads session.
 */
export const FormPageTemplate: FunctionComponent<Props> = suspense(({
  slug,
  'data-testid': testId,
  meta: metaProps
}) => {
  const {
    fetching,
    page: {
      mode,
      meta,
      sections: {
        items: fragments
      } = EMPTY_COLLECTION,
      submit
    }
  } = useFormPage({
    slug
  });
  const {
    query: {
      next
    }
  } = useRouter();
  useMode(mode as ThemeMode);
  return <div className={styles.page} data-testid={testId}>
                {meta && <PageMeta id={meta.sys.id} name={metaProps?.name ?? slug} meta={meta} {...metaProps} />}

                {fetching ? <PageLoadingState /> : <>
                        {fragments.map(s => s.type === 'Fieldset' ? <FieldsetWrapper key={s.customId} next={next as string} submit={submit} {...s} /> : renderSection(s))}
                    </>}
            </div>;
}, <Spinner size='large' />);
const FieldsetWrapper: FunctionComponent<FieldsetFragment & {
  submit?: SubmitFragment;
  next?: string;
}> = suspense(({
  customId,
  next,
  submit,
  section
}) => <Fieldset section={section} customId={customId as CustomFieldsetId} key={customId} next={typeof next === 'string' ? next : undefined} renderSubmit={control => <Submit {...submit} order={submit?.order === 'reverse' ? 'reverse' : 'default'} control={control as Control} />} />, <Spinner size='large' />);
type Props = PageTemplateProps & {
  next?: string;
};